@import "~normalize.css/normalize.css";
@import "~@ctrl/ngx-emoji-mart/picker.css";
// @import "./app/shared/styles/app-theme.scss";
@import "./app/shared/styles/utilities.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  min-width: 350px;
  overflow-x: hidden;
  background-color: #f6f7f9;
}

.toast-custom-cl {
  background-color: white !important;
  color: #333333 !important;
}

.mobile-nav-header {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 60px;
  box-shadow: 1px -3px 10px #333333;
}