.carriage_returns {
  white-space: pre-wrap;
}

.cursor_pointer {
  cursor: pointer;
}

.text-muted {
  color: #a3a2a2;
}

// text alignment utilities
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// padding utilities
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.p-5 {
  padding: 30px !important;
}

// margin utilities
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.m-5 {
  margin: 30px !important;
}

.mt-4 {
  margin-top: 20px !important;
}

// overflow utilities
.o-hidden {
  overflow: hidden !important;
}

.o-auto {
  overflow: auto !important;
}

// height utilities
.h-0 {
  height: 0 !important;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

// width utilities
.w-0 {
  width: 0 !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.vw-100 {
  width: 100vw;
}

// font-size utilities
.fs-0-6 {
  font-size: 0.6em !important;
}

.fs-0-7 {
  font-size: 0.7em !important;
}

.fs-0-8 {
  font-size: 0.8em !important;
}

.fs-0-9 {
  font-size: 0.9em !important;
}

.fs-1 {
  font-size: 1em !important;
}

.fs-1-1 {
  font-size: 1.1em !important;
}

.fs-1-2 {
  font-size: 1.2em !important;
}

.fs-1-3 {
  font-size: 1.3em !important;
}

.fs-1-4 {
  font-size: 1.4em !important;
}

.fs-1-5 {
  font-size: 1.5em !important;
}

.fs-2 {
  font-size: 2em !important;
}

.fs-3 {
  font-size: 3em !important;
}

.fs-4 {
  font-size: 4em !important;
}

.fs-5 {
  font-size: 5em !important;
}